var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({class:[
    _vm.containerClassName,
    {
      'slds-input-has-icon':
        _vm.variant !== 'counter' &&
        (_vm.hasSlot('icon-left') || _vm.hasSlot('icon-right') || _vm.clearable),
      'slds-input-has-icon_left':
        _vm.hasSlot('icon-left') && _vm.hasSlot('icon-right') === false,
      'slds-input-has-icon_right':
        (!_vm.hasSlot('icon-left') && _vm.hasSlot('icon-right')) || _vm.clearable,
      'slds-input-has-icon_left-right':
        _vm.variant !== 'counter' &&
        _vm.hasSlot('icon-left') &&
        (_vm.hasSlot('icon-right') || _vm.clearable),
      'slds-input-has-fixed-addon': _vm.hasSlot('addon-right'),
    } ]},'div',_vm.containerAttrs,false),[_vm._t("icon-left"),(!_vm.isStatic)?[_c('input',_vm._g(_vm._b({ref:"input"},'input',Object.assign({}, _vm.$attrs,
        {class: [
          'slds-input',
          { 'slds-text-align_left': _vm.variant === 'counter' && _vm.readonly },
          _vm.className ],
        id: _vm.id,
        readonly: _vm.readonly,
        style: _vm.inputStyle,
        value: _vm.value}),false),Object.assign({}, _vm.$listeners,
        {input: function ($event) { return _vm.$emit('input', $event.target.value); }}))),(
        _vm.clearable && _vm.value !== undefined && _vm.value !== null && _vm.value !== ''
      )?_c('ButtonIcon',{staticClass:"slds-input__icon slds-input__icon_right",attrs:{"title":"Clear"},on:{"click":_vm.clear}},[_c('ButtonIconIcon',{staticClass:"slds-icon-text-light",attrs:{"name":"clear"}})],1):_vm._e(),[_vm._t("icon-right")],(_vm.hasSlot('addon-right'))?_c('div',{staticClass:"slds-form-element__addon"},[_vm._t("addon-right")],2):_vm._e()]:[_c('div',_vm._g({class:[
        'slds-form-element__static',
        'slds-grid',
        { 'slds-grid_align-spread': _vm.variant !== 'counter' } ]},Object.assign({}, _vm.$listeners)),[_vm._v(" "+_vm._s(_vm.value)+" "),_vm._t("inlineEditTrigger")],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }