import type { PropType, VNode, VNodeChildren, VNodeData } from 'vue';
import Vue from 'vue';
import type { WithRefs } from 'vue-typed-refs';
import type { WithProperties } from 'vue-typed-properties';
import invariant from 'invariant';

import type { Undefined, ClassNames } from '@/types';
import { isDefined, hasSlot, normalizeSlot } from '@/util';

import type { Nullable, Pixels } from '../types';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ButtonIconIcon from '../ButtonIcon/ButtonIconIcon';
import Spinner from '../Spinner/Spinner';
import Checkbox from '../Checkbox.vue';
import TableColumn from './TableColumn';
import CustomColumn from './CustomColumn';
import type {
  TableResizable,
  TableResizableAdjacent,
} from './private/Table.types';
import type {
  TableColumnKey,
  TableEditable,
  TableSorting,
  TableSortingWithColumn,
} from './Table.types';
import {
  TableItem,
  Selected,
  HeaderScopedSlotData,
  CellScopedSlotData,
  TableExpandEventPayload,
  ClickCellEventPayload,
  IdentityFn,
  SelectedIdentityFn,
  SelectedPredicate,
  ExpandedIdentityFn,
  ExpandedPredicate,
  SortOrder,
  TableSortingMode,
} from './Table.types';
import { MIN_COLUMN_WIDTH } from './private/Table.constants';
import EditableTableColumn from './EditableTableColumn';
import TablePopover from './TablePopover';
import TablePopoverSelect from './TablePopoverSelect';

import './Table.scss';

const SELECTION_COLUMN_WIDTH = '3.25rem';

export default (
  Vue as WithProperties<
    {
      shiftPressed: boolean;
      lastSelectedRowIndex: Undefined<number>;
      editable: Nullable<TableEditable>;
      _resizable: Nullable<TableResizable>;
    },
    WithRefs<{ headers: HTMLTableCellElement[]; fixed?: HTMLDivElement[] }>
  >
).extend({
  name: 'SldsTable',
  props: {
    columns: {
      type: Array as PropType<Array<TableColumn | CustomColumn>>,
      required: true,
    },
    items: {
      type: Array as PropType<TableItem[]>,
      required: true,
    },
    identity: {
      type: Function as PropType<IdentityFn>,
      required: true,
    },
    selected: {
      type: [Array, Set] as PropType<Selected>,
      default: () => [],
    },
    expanded: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    sorting: {
      type: Object as PropType<Nullable<TableSorting>>,
      default: null,
    },
    sortingMode: {
      type: Number as PropType<TableSortingMode>,
      default: TableSortingMode.CLIENT,
      validator: (val: TableSortingMode) =>
        Object.values(TableSortingMode).includes(val),
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
    tableClassName: {
      type: [String, Object, Array],
      default: undefined,
    },
    selectedIdentity: {
      type: Function as PropType<SelectedIdentityFn | null>,
      default: null,
    },
    selectedPredicate: {
      type: Function as PropType<SelectedPredicate | null>,
      default: null,
    },
    expandedIdentity: {
      type: Function as PropType<ExpandedIdentityFn | null>,
      default: null,
    },
    expandedPredicate: {
      type: Function as PropType<ExpandedPredicate | null>,
      default: null,
    },
    noDataMessage: {
      type: String,
      default: 'No matching rows',
    },
    noSelectionControls: {
      type: Boolean,
      default: false,
    },
    verticalAlign: {
      type: String as PropType<'middle' | 'top'>,
      default: 'middle' as const,
      validator: (val) => val === 'middle' || val === 'top',
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localSorting: this.sorting,
      editing: false,
      fixedWith: new Map<TableColumnKey, Pixels>(),
      resizableWidth: new Map<TableColumnKey, Pixels>(),
    };
  },
  computed: {
    identities(): Set<string> {
      return new Set(this.items.map(this.identity));
    },
    sortable(): boolean {
      return this.columns.some(
        (column) => column instanceof TableColumn && column.sorting !== null
      );
    },
    selectable(): boolean {
      return this.selectedIdentity !== null;
    },
    resizable(): boolean {
      return this.columns.some((column) => column.resizable === true);
    },
    expandable(): boolean {
      return this.expandedIdentity !== null;
    },
    hasSelectionControls(): boolean {
      if (this.noSelectionControls) {
        return false;
      }
      return this.selectable;
    },
    sorting$(): Nullable<TableSortingWithColumn> {
      if (this.localSorting === null) return null;
      const column = this.columnsByKey.get(this.localSorting.key) ?? null;
      if (column === null) return null;
      if (column.isSortable()) {
        return { column, order: this.localSorting.order };
      }
      return null;
    },
    sortedItems(): TableItem[] {
      if (
        this.sortingMode === TableSortingMode.CLIENT &&
        this.sorting$ !== null &&
        this.sorting$.column.sorting.predicate !== null
      ) {
        const clone = this.items.slice(0);
        clone.sort(
          this.sorting$.column.sorting.predicate.bind(this.sorting$.column)
        );
        if (this.sorting$.order === SortOrder.DESC) {
          clone.reverse();
        }
        return clone;
      }
      return this.items;
    },
    minWidth(): string | undefined {
      if (this.fixed === false) return undefined;

      const widthAccumulator = this.columns
        .map((column) => column.getMinWidth())
        .filter(isDefined);

      return widthAccumulator.length > 0
        ? `calc(${widthAccumulator.join(' + ')})`
        : undefined;
    },
    tableClass(): ClassNames {
      return [
        {
          'slds-table': true,
          'slds-table_resizable-cols':
            this.resizable || this.sortable || this.selectable,
          'slds-table_fixed-layout': this.fixed,
          'slds-table_bordered': this.bordered,
          'slds-table--header-fixed': this.fixedHeader,
        },
        this.tableClassName,
      ];
    },
    headerSelectionCheckboxLabel(): string {
      return this.$selected.size > 0 ? 'Unselect All' : 'Select All';
    },
    headerSelectionCheckboxState(): boolean {
      const { selectedPredicate } = this;
      if (this.items.length === 0) {
        return false;
      }
      if (selectedPredicate !== null) {
        return this.items.every(selectedPredicate);
      }
      const { identities, $selected } = this;
      if ($selected.size === 0) return false;
      for (const identity of identities) {
        if ($selected.has(identity) === false) return false;
      }
      return true;
    },
    isHeaderSelectionCheckboxIndeterminate(): boolean {
      const count = this.items.filter((item) => {
        const res = this.isSelected(item);
        return res === null || res === false;
      }).length;

      return count > 0 && count < this.items.length;
    },
    $selected(): Set<string> {
      return Array.isArray(this.selected)
        ? new Set(this.selected)
        : this.selected;
    },
    columnsByKey(): Map<TableColumnKey, TableColumn> {
      const map = new Map<TableColumnKey, TableColumn>();
      for (const column of this.columns) {
        if (column instanceof TableColumn) {
          map.set(column.key, column);
        }
      }
      return map;
    },
  },
  watch: {
    sorting: {
      handler(value: Nullable<TableSorting>) {
        this.localSorting = value;
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.recalculateFixedCellsWidth();
    });
  },
  created() {
    this.lastSelectedRowIndex = undefined;
    this.shiftPressed = false;
    this._resizable = null;
    window.addEventListener('blur', this.onBlur);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('blur', this.onBlur);
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
    window.removeEventListener('mousemove', this.onResizableMouseMove);
    window.removeEventListener('mouseup', this.onResizalbeMouseUp);
  },
  methods: {
    onBlur() {
      this.shiftPressed = false;
    },
    onKeyDown(e: KeyboardEvent) {
      this.shiftPressed = e.shiftKey;
    },
    onKeyUp(e: KeyboardEvent) {
      this.shiftPressed = e.shiftKey;
    },
    sortHandler(column: TableColumn) {
      let order = SortOrder.DESC;
      if (
        this.sorting$ !== null &&
        this.sorting$.column.key === column.key &&
        this.sorting$.order === SortOrder.DESC
      ) {
        order = SortOrder.ASC;
      }
      this.localSorting = {
        key: column.key,
        order,
      };
      this.$emit('update:sorting', this.localSorting);
    },
    onHeaderSelectionInput(value: boolean) {
      if (this.isHeaderSelectionCheckboxIndeterminate) {
        this.unselectAll();
      } else if (value) {
        this.selectAll();
      } else {
        this.unselectAll();
      }
    },
    selectAll() {
      this.select(this.identities);
      for (const item of this.items) {
        this.$emit('select:item', item);
      }
    },
    unselectAll() {
      const { identities, selected } = this;
      const result = new Set<string>();
      for (const identity of selected) {
        if (identities.has(identity)) continue;
        result.add(identity);
      }
      this.select(result);
      for (const item of this.items) {
        this.$emit('deselect:item', item);
      }
    },
    isSelected(item: TableItem): boolean | null {
      if (this.selectedPredicate !== null) {
        return this.selectedPredicate(item);
      }

      if (this.selectedIdentity === null) {
        return false;
      }

      return this.$selected.has(this.selectedIdentity(item));
    },
    selectionHandler(value: boolean, item: TableItem, index: number) {
      const { selectedIdentity } = this;
      if (selectedIdentity === null) {
        return;
      }

      let payload: string[];

      if (value) {
        payload = [...this.selected, selectedIdentity(item)];
        this.$emit('select:item', item);
      } else {
        payload = [...this.selected].filter(
          (selected) => selected !== selectedIdentity(item)
        );
        this.$emit('deselect:item', item);
      }
      if (this.shiftPressed && this.lastSelectedRowIndex !== undefined) {
        const startIndex = Math.min(index, this.lastSelectedRowIndex);
        const endIndex = Math.max(index, this.lastSelectedRowIndex);
        const range = this.getRenderedItems()
          .slice(startIndex, endIndex + 1)
          .map(selectedIdentity);
        if (value) {
          const items = range.filter((i) => payload.includes(i) === false);
          payload = payload.concat(items);
        } else {
          payload = payload.filter((i) => range.includes(i) === false);
        }
      }

      this.select(payload);

      this.lastSelectedRowIndex = index;
    },
    select(value: Selected) {
      if (Array.isArray(this.selected)) {
        this.$emit('select', Array.isArray(value) ? value : [...value]);
        return;
      }
      this.$emit('select', Array.isArray(value) ? new Set(value) : value);
    },
    edit(e: MouseEvent, item: TableItem, column: EditableTableColumn) {
      const td = (e.target as HTMLElement).closest('td');
      if (td === null) return;
      this.editing = true;
      this.editable = { item, column, td };
    },
    unedit() {
      this.editable = null;
      this.editing = false;
    },
    onPopoverSelectInput(v: unknown, editable: TableEditable) {
      this.$emit('edit', v, editable.item);
      this.unedit();
    },
    recalculateFixedCellsWidth() {
      const cells = this.$refs.fixed ?? [];
      const map = new Map<TableColumnKey, Pixels>();
      for (const cell of cells) {
        const parent = cell.parentElement;
        if (parent === null) continue;
        if (parent.style.width !== '') continue;
        const { key } = parent.dataset;
        if (key === undefined) continue;
        map.set(key, parent.clientWidth);
      }
      this.fixedWith = map;
    },
    renderHeaderSelection() {
      const h = this.$createElement;

      const content =
        this.items.length > 0
          ? [
              h('span', { staticClass: 'slds-assistive-text' }, 'Choose a row'),
              h(
                'div',
                { staticClass: 'slds-th__action slds-th__action_form' },
                [
                  h('slds-checkbox', {
                    props: {
                      checked: this.headerSelectionCheckboxState,
                      indeterminate:
                        this.isHeaderSelectionCheckboxIndeterminate,
                      label: this.headerSelectionCheckboxLabel,
                      hideLabel: true,
                    },
                    staticClass: 'bc-table__checkbox',
                    on: { change: this.onHeaderSelectionInput },
                  }),
                ]
              ),
            ]
          : null;

      return h(
        'th',
        {
          staticClass:
            'slds-text-align_right slds-cell_action-mode bc-table__cell bc-table__cell_checkbox',
          style: {
            width: SELECTION_COLUMN_WIDTH,
          },
        },
        this.fixedHeader
          ? [
              h(
                'div',
                {
                  staticClass: 'slds-cell-fixed',
                  style: {
                    width: SELECTION_COLUMN_WIDTH,
                  },
                },
                content
              ),
            ]
          : content
      );
    },
    renderHeader() {
      const h = this.$createElement;

      const headers = this.columns.map((column, index) => {
        const scopedSlot = this.$scopedSlots[`header:${column.key}`];
        const headerScopedSlotData: HeaderScopedSlotData = {
          column,
          index,
          items: this.sortedItems,
        };
        const result =
          scopedSlot !== undefined
            ? scopedSlot(headerScopedSlotData)
            : h(
                'span',
                {
                  staticClass: 'slds-truncate',
                  class: { 'slds-assistive-text': column.noTitle === true },
                },
                column.title
              );
        let value = result;
        let sortable = false;

        if (column instanceof TableColumn) {
          sortable = column.sorting !== null;

          if (column.sorting !== null) {
            value = h(
              'a',
              {
                attrs: { role: 'button', href: '#' },
                on: {
                  click: (e: Event) => {
                    e.preventDefault();
                    this.sortHandler(column);
                  },
                },
                staticClass: 'slds-th__action slds-text-link_reset',
              },
              [
                h('span', { staticClass: 'slds-assistive-text' }, 'Sort by: '),
                h(
                  'div',
                  {
                    staticClass:
                      'slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate',
                  },
                  [
                    result,
                    h('slds-icon', {
                      props: {
                        category: 'utility',
                        name: 'arrowdown',
                        svgClassName: 'slds-is-sortable__icon',
                      },
                    }),
                  ]
                ),
              ]
            );
          } else if (this.sortable || this.selectable) {
            value = h('div', { staticClass: 'slds-th__action' }, [
              value,
              this.hasSlot(`header-append:${column.key}`)
                ? this.normalizeSlot(`header-append:${column.key}`)
                : null,
            ]);
          }
        }

        const sorted =
          this.localSorting !== null && column.key === this.localSorting.key
            ? this.localSorting.order
            : undefined;

        const content = column.resizable
          ? [
              value,
              column.resizable
                ? h('div', { staticClass: 'slds-resizable' }, [
                    h(
                      'span',
                      {
                        attrs: {
                          'data-key': column.key,
                        },
                        staticClass: 'slds-resizable__handle',
                        on: {
                          mousedown: this.onResizableMouseDown,
                        },
                      },
                      [h('span', { staticClass: 'slds-resizable__divider' })]
                    ),
                  ])
                : null,
            ]
          : [value];

        const width = this.resizableWidth.has(column.key)
          ? `${this.resizableWidth.get(column.key)}px`
          : column.width;

        return h(
          'th',
          {
            key: column.key,
            ref: 'headers',
            refInFor: true,
            staticClass: 'bc-table__cell',
            class: [
              column.className,
              column.headerClassName,
              {
                'slds-is-sortable': sortable,
                'slds-cell_action-mode': sortable,
                'slds-is-sorted': sorted !== undefined,
                [`slds-is-sorted_${
                  sorted === SortOrder.DESC ? 'desc' : 'asc'
                }`]: sorted !== undefined,
              },
            ],
            style: {
              width,
              ...column.style,
              ...column.hederStyle,
            },
            attrs: {
              'data-key': column.key,
            },
          },
          this.fixedHeader
            ? [
                h(
                  'div',
                  {
                    ref: 'fixed',
                    refInFor: true,
                    staticClass: 'slds-cell-fixed',
                    style: {
                      width:
                        width !== undefined
                          ? column.width
                          : `${this.fixedWith.get(column.key)}px`,
                    },
                  },
                  content
                ),
              ]
            : content
        );
      });

      if (this.hasSelectionControls) {
        headers.unshift(this.renderHeaderSelection());
      }

      return h('thead', undefined, [
        h('tr', { staticClass: 'slds-line-height_reset' }, headers),
      ]);
    },
    renderRow(item: TableItem, index: number) {
      const h = this.$createElement;
      let hasEditable = false;

      const identity = this.identity(item);

      const content = this.columns.map((column, i) => {
        const scopedSlot = this.$scopedSlots[`cell:${column.key}`];
        const dataClassName =
          column.dataClassName instanceof Function
            ? column.dataClassName(item)
            : column.dataClassName;
        const data: VNodeData = {
          key: i,
          staticClass: 'bc-table__cell',
          class: [
            column.className,
            dataClassName,
            {
              'slds-align-top': this.verticalAlign === 'top',
              'slds-cell-wrap': this.wrapped,
            },
          ],
          style: {
            ...column.style,
            ...column.dataStyle,
          },
          on: {
            click: () => {
              const payload: ClickCellEventPayload<TableItem> = {
                item,
                key: column.key,
              };
              this.$emit('click:cell', payload);
            },
          },
        };

        if (scopedSlot !== undefined) {
          let value: Undefined<unknown>;
          if (column instanceof TableColumn) {
            const itemValue = column.getter(item);
            value = column.formatter ? column.formatter(itemValue) : itemValue;
          }
          const selectedIdentity =
            this.selectedIdentity !== null
              ? this.selectedIdentity(item)
              : undefined;
          const cellScopedSlotData: CellScopedSlotData = {
            value,
            item,
            identity,
            index,
            items: this.sortedItems,
            selected: this.isSelected(item),
            selectedIdentity,
            expanded: this.isExpanded(item),
            toggleExpanded: this.expandable ? this.toggleExpanded : undefined,
          };
          return h('td', data, scopedSlot(cellScopedSlotData));
        }

        if (column instanceof CustomColumn) {
          return h('td', data);
        }

        const itemValue = column.getter(item);

        const value = column.formatter
          ? column.formatter(itemValue)
          : itemValue;

        if (column instanceof EditableTableColumn) {
          data.class.push('slds-cell-edit slds-cell_action-mode');
          if (data.attrs === undefined) {
            data.attrs = { role: 'gridcell' };
          } else {
            data.attrs.role = 'gridcell';
          }
          hasEditable = true;
        }

        let c = value;

        if (
          (column.truncate || this.fixed) &&
          column instanceof EditableTableColumn === false &&
          this.wrapped === false
        ) {
          c = h(
            'div',
            { attrs: { title: value }, staticClass: 'slds-truncate' },
            value
          );
        }

        if (column instanceof EditableTableColumn) {
          c = h('span', { staticClass: 'slds-grid slds-grid_align-spread' }, [
            h(
              'span',
              { attrs: { title: value }, staticClass: 'slds-truncate' },
              value
            ),
            value !== undefined && value !== null && value !== ''
              ? h(
                  ButtonIcon,
                  {
                    props: {
                      // @ts-expect-error TODO: figure out how to extract name
                      title: `Edit ${column.title} of ${item.label}`,
                    },
                    staticClass: 'slds-cell-edit__button slds-m-left_x-small',
                    on: {
                      click: (e: MouseEvent) => this.edit(e, item, column),
                    },
                  },
                  [
                    h(ButtonIconIcon, {
                      props: { name: 'edit', hint: true },
                      staticClass: 'slds-button__icon_edit',
                    }),
                  ]
                )
              : null,
          ]);
        }

        return h('td', data, [c]);
      });

      const selected = this.isSelected(item);

      if (this.hasSelectionControls) {
        content.unshift(
          h(
            'td',
            {
              attrs: { role: 'gridcell' },
              style: { width: SELECTION_COLUMN_WIDTH },
              staticClass:
                'slds-text-align_right slds-cell_action-mode bc-table__cell bc-table__cell_checkbox',
              class: { 'slds-align-top': this.verticalAlign === 'top' },
            },
            [
              h(Checkbox, {
                props: {
                  checked: selected,
                  indeterminate: selected === null,
                  label: `Select item ${index + 1}`,
                  hideLabel: true,
                },
                staticClass: 'bc-table__checkbox',
                on: {
                  change: (value: boolean) =>
                    this.selectionHandler(value, item, index),
                },
              }),
            ]
          )
        );
      }

      return h(
        'tr',
        {
          key: identity,
          attrs: {
            'aria-selected': selected,
          },
          class: {
            'slds-is-selected': selected,
            'slds-hint-parent': hasEditable,
          },
        },
        content
      );
    },
    getRenderedItems() {
      return this.sortedItems;
    },
    toggleExpanded(item: TableItem) {
      const { expandedIdentity } = this;
      if (expandedIdentity === null) {
        return;
      }
      let payload: TableExpandEventPayload;
      if (this.isExpanded(item)) {
        payload = this.expanded.filter(
          (expanded) => expanded !== expandedIdentity(item)
        );
      } else {
        payload = this.expanded.concat([expandedIdentity(item)]);
      }

      this.$emit('expand', payload);
      this.$emit('update:expanded', payload);
    },
    isExpanded(item: TableItem) {
      if (this.expandedPredicate !== null) {
        return this.expandedPredicate(item);
      }

      if (this.expandedIdentity === null) {
        return false;
      }

      return this.expanded.includes(this.expandedIdentity(item));
    },
    _resisableInBounds(dx: Pixels, width: Pixels) {
      const newWidth = width - dx;
      return newWidth >= MIN_COLUMN_WIDTH;
    },
    onResizableMouseMove(e: MouseEvent) {
      if (this._resizable === null) return;
      const dx = this._resizable.x - e.clientX;
      if (this._resisableInBounds(dx, this._resizable.width) === false) return;
      const { adjacent } = this._resizable;
      if (adjacent !== null) {
        if (this._resisableInBounds(-dx, adjacent.width) === false) return;
      }
      this._resizable.dx = dx;
      this._resizable.divider.style.transform = `translateX(${-dx}px)`;
    },
    onResizalbeMouseUp() {
      document.removeEventListener('mousemove', this.onResizableMouseMove);
      document.removeEventListener('mouseup', this.onResizalbeMouseUp);
      if (this._resizable === null) return;

      const newWidth = this._resizable.width - this._resizable.dx;
      const map = new Map(this.resizableWidth);
      map.set(this._resizable.key, newWidth);
      const { adjacent } = this._resizable;
      if (adjacent !== null) {
        const delta = this._resizable.width - newWidth;
        map.set(adjacent.key, adjacent.width + delta);
      }
      this.resizableWidth = map;
      this._resizable.divider.style.transform = '';
      this._resizable = null;
    },
    onResizableMouseDown(e: MouseEvent) {
      const handle = e.currentTarget as HTMLSpanElement;
      const divider = handle.children[0] as HTMLSpanElement;
      const key = handle.dataset.key as TableColumnKey;
      const header =
        this.$refs.headers.find((h) => h.dataset.key === key) ?? null;
      if (header === null) return;
      let adjacent: Nullable<TableResizableAdjacent> = null;
      const adjacentHeader =
        header.nextElementSibling as Nullable<HTMLTableCellElement>;
      if (adjacentHeader !== null) {
        adjacent = {
          key: adjacentHeader.dataset.key as TableColumnKey,
          header: adjacentHeader,
          width: adjacentHeader.getBoundingClientRect().width,
        };
      }
      const { width } = header.getBoundingClientRect();
      this._resizable = {
        key,
        x: e.clientX,
        dx: 0,
        width,
        header,
        divider,
        adjacent,
      };
      document.addEventListener('mousemove', this.onResizableMouseMove);
      document.addEventListener('mouseup', this.onResizalbeMouseUp);
    },
    renderTableElement(content: VNodeChildren) {
      const h = this.$createElement;

      return h(
        'table',
        {
          style: {
            minWidth: this.minWidth,
          },
          class: this.tableClass,
        },
        content
      );
    },
    renderTable() {
      const h = this.$createElement;

      return this.renderTableElement([
        this.renderHeader(),
        [
          h(
            'tbody',
            undefined,
            this.sortedItems.length > 0
              ? this.sortedItems.map((item, index) => {
                  const content = this.renderRow(item, index);

                  if (this.isExpanded(item)) {
                    const slot = this.$scopedSlots.expanded;

                    invariant(slot, "`'expanded'` slot is not provided");

                    const expandedScopedSlotData = { item, index };

                    return [
                      content,
                      h(
                        'tr',
                        {
                          staticClass: 'bc-table__details',
                        },
                        [
                          h(
                            'td',
                            {
                              attrs: {
                                colspan: this.hasSelectionControls
                                  ? this.columns.length + 1
                                  : this.columns.length,
                              },
                              staticClass: 'bc-table__cell',
                            },
                            slot(expandedScopedSlotData)
                          ),
                        ]
                      ),
                    ];
                  }

                  return content;
                })
              : [this.loading ? null : this.renderGag()]
          ),
        ],
      ]);
    },
    renderGag() {
      const h = this.$createElement;

      return h('tr', undefined, [
        h(
          'td',
          {
            attrs: {
              colspan: this.hasSelectionControls
                ? this.columns.length + 1
                : this.columns.length,
            },
            staticClass: 'bc-table__cell slds-text-align_center',
          },
          [this.noDataMessage]
        ),
      ]);
    },
    hasSlot,
    normalizeSlot,
  },
  render(h): VNode {
    if (this.fixedHeader) {
      return h(
        'div',
        {
          staticClass: 'slds-table--header-fixed_container',
          class: { 'slds-grow': this.loading },
        },
        [
          h(
            'div',
            {
              ref: 'scroller',
              staticClass:
                'uiScroller scroller-wrapper scroll-bidirectional native',
              on:
                this.$listeners.scroll !== undefined
                  ? {
                      scroll: this.$listeners.scroll,
                    }
                  : undefined,
            },
            [
              this.renderTable(),
              this.loading
                ? h(Spinner, { props: { container: true, color: 'brand' } })
                : undefined,
            ]
          ),
          this.editing && this.editable !== null
            ? h(TablePopover, { props: { editable: this.editable } }, [
                h(TablePopoverSelect, {
                  props: { editable: this.editable },
                  on: { input: this.onPopoverSelectInput, cancel: this.unedit },
                }),
              ])
            : null,
        ]
      );
    }

    return this.renderTable();
  },
});
