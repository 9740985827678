import { ref, computed } from '@vue/composition-api';
import { useLocalStorage } from '@vueuse/core';

import type { Maybe } from '@/types';

import type { BranchGroupId } from '../branch.types';

const selected = useLocalStorage<BranchGroupId[]>('branch-filter', ref([]));

export default function useBranchesFilter() {
  const isActive = computed(() => selected.value.length > 0);

  function filter<
    T extends { branchGroup?: Maybe<{ id: BranchGroupId; hidden: boolean }> },
  >(branch: T): boolean {
    if (selected.value.length === 0) return branch.branchGroup?.hidden !== true;
    const branchGroupId = branch.branchGroup?.id;
    if (branchGroupId === undefined) return false;
    return selected.value.includes(branchGroupId);
  }

  function isSelected(id: BranchGroupId): boolean {
    return selected.value.includes(id);
  }

  function toggle(id: BranchGroupId, value: boolean): void {
    if (value) {
      selected.value = [...selected.value, id];
    } else {
      selected.value = selected.value.filter((i) => i !== id);
    }
  }

  function clear() {
    selected.value = [];
  }

  return {
    isActive,
    isSelected,
    filter,
    toggle,
    clear,
  };
}
