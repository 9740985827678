import { TenantSettingsKey } from '@/data';
import { useTenantSetting } from '@/composables';

export default function useNextgen() {
  const { enabled: isNextgen } = useTenantSetting(
    TenantSettingsKey.NEXTGEN_SYNC
  );
  return {
    isNextgen,
  };
}
