<template>
  <div class="slds-color-picker bc-color-picker">
    <div class="slds-form-element slds-color-picker__summary">
      <label
        :for="id"
        class="slds-form-element__label slds-color-picker__summary-label"
      >
        {{ label }}
      </label>
      <div class="slds-form-element__control">
        <button
          type="button"
          class="slds-button slds-color-picker__summary-button slds-button_icon slds-button_icon-more"
          title="Choose Color"
          @click="onOpen"
        >
          <span class="slds-swatch" :style="{ backgroundColor: color }">
            <span class="slds-assistive-text">{{ color }}</span>
          </span>
          <ButtonIconIcon
            name="down"
            size="small"
            class="slds-m-left_xx-small"
            aria-hidden="true"
          />
          <span class="slds-assistive-text"
            >Choose a color. Current color: {{ color }}</span
          >
        </button>
        <div class="slds-color-picker__summary-input">
          <input :id="id" v-model="color" class="slds-input" />
        </div>
      </div>
    </div>
    <input
      ref="picker"
      v-model.lazy="color"
      type="color"
      class="bc-color-picker__picker"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from '@vue/composition-api';

import type { Nullable } from '@/types';
import { uid } from '@/util';

import ButtonIconIcon from './ButtonIcon/ButtonIconIcon';

export default defineComponent({
  name: 'ColorInput',
  components: {
    ButtonIconIcon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: 'Color',
    },
  },
  setup(props, { emit }) {
    const id = `color-picker-${uid()}`;
    const color = computed({
      get: () => props.value,
      set(value: string) {
        emit('input', value.toLowerCase());
      },
    });
    const picker = ref<Nullable<HTMLInputElement>>(null);
    function onOpen() {
      if (picker.value === null) return;
      picker.value.click();
    }
    return { color, id, picker, onOpen };
  },
});
</script>

<style lang="scss">
.bc-color-picker {
  &__picker {
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .slds-form-element__control {
    display: flex;
  }

  .slds-color-picker__summary-input {
    flex-grow: 1;
  }
}
</style>
