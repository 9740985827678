<template>
  <div
    :class="[
      containerClassName,
      {
        'slds-input-has-icon':
          variant !== 'counter' &&
          (hasSlot('icon-left') || hasSlot('icon-right') || clearable),
        'slds-input-has-icon_left':
          hasSlot('icon-left') && hasSlot('icon-right') === false,
        'slds-input-has-icon_right':
          (!hasSlot('icon-left') && hasSlot('icon-right')) || clearable,
        'slds-input-has-icon_left-right':
          variant !== 'counter' &&
          hasSlot('icon-left') &&
          (hasSlot('icon-right') || clearable),
        'slds-input-has-fixed-addon': hasSlot('addon-right'),
      },
    ]"
    v-bind="containerAttrs"
  >
    <slot name="icon-left" />

    <template v-if="!isStatic">
      <input
        ref="input"
        v-bind="{
          ...$attrs,
          class: [
            'slds-input',
            { 'slds-text-align_left': variant === 'counter' && readonly },
            className,
          ],
          id,
          readonly,
          style: inputStyle,
          value,
        }"
        v-on="{
          ...$listeners,
          input: ($event) => $emit('input', $event.target.value),
        }"
      />
      <ButtonIcon
        v-if="
          clearable && value !== undefined && value !== null && value !== ''
        "
        title="Clear"
        class="slds-input__icon slds-input__icon_right"
        @click="clear"
      >
        <ButtonIconIcon name="clear" class="slds-icon-text-light" />
      </ButtonIcon>
      <template>
        <slot name="icon-right" />
      </template>
      <div v-if="hasSlot('addon-right')" class="slds-form-element__addon">
        <slot name="addon-right" />
      </div>
    </template>

    <template v-else>
      <div
        :class="[
          'slds-form-element__static',
          'slds-grid',
          { 'slds-grid_align-spread': variant !== 'counter' },
        ]"
        v-on="{
          // useful to bind @click
          ...$listeners,
        }"
      >
        {{ value }}

        <slot name="inlineEditTrigger" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import type { WithRefs } from 'vue-typed-refs';

import { hasSlot } from '@/util';

import ButtonIcon from './ButtonIcon/ButtonIcon';
import ButtonIconIcon from './ButtonIcon/ButtonIconIcon';

export type Refs = {
  input: HTMLInputElement;
};

/*
 * This component was created to allow the DIV wrapped input to be used within
 * other components such as combobox. This components API is not public.
 */
export default (Vue as WithRefs<Refs>).extend({
  components: {
    ButtonIcon,
    ButtonIconIcon,
  },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    /**
     * **Assistive text for accessibility.**
     * This object is merged with the default props object on every render.
     * * `spinner`: Assistive text on the spinner.
     */
    assistiveText: {
      type: Object as PropType<{
        spinner: string;
      }>,
      default() {
        return {
          spinner: 'Loading ...',
        };
      },
    },
    /**
     * Class names to be added to the `input` element.
     */
    className: {},
    /**
     * Class names to be added to the outer container `div` of the input.
     */
    containerClassName: {},
    /**
     * Props to be added to the outer container `div` of the input (excluding `containerClassName`).
     */
    containerAttrs: Object,
    /**
     * Every input must have a unique ID in order to support keyboard navigation and ARIA support.
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Style object to be added to `input` node
     */
    inputStyle: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
    },
    /**
     * Displays the value of the input statically. This follows the static input UX pattern.
     */
    isStatic: Boolean,
    /**
     * This label appears above the input.
     */
    label: String,
    /**
     * Displays the value of the input as read-only. This is used in the inline edit UX pattern.
     */
    readonly: Boolean,
    /**
     * The input value, bound with `v-model`.
     */
    value: [String, Number],
    /**
     * Which UX pattern of input? The default is `base` while other option is `counter`
     */
    variant: {
      type: String,
      validator(val: string) {
        return ['base', 'counter'].includes(val);
      },
      default: 'base',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hasSlot,
    clear() {
      this.$emit('input', '');
    },
  },
});
</script>
